
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    authChecked: false,
    loggedIn: false,
    saving: false,
    advertiserId: '',
    gaData: null,
    message: '',
    userId: '',
    googleClientID:
      process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID ||
      '523663729702-vg84m845lg4603v3cbnhjnru96ishkr6.apps.googleusercontent.com', // todo: make this and public/index.html use the value from .env file(s)
    error: '',
    showIntro: true,
  }),
  props: ['scopes', 'agencyId'],
  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).GoogleApiLoaded = this.googleApiLoaded;
    this.LoadGoogleApi();
  },
  computed: {},
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    alreadySignedIn(googleUser: any): void {
      this.authChecked = true;

      // const _g = (window as any).gapi;
      // const auth2 = _g.auth2.getAuthInstance();

      if (googleUser) {
        this.loggedIn = true;
        const profile = googleUser.getBasicProfile();
        this.userId = profile.getId();
      }
    },
    googleApiLoaded(): void {
      setTimeout(() => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const _gNew = (window as any).gapi;
          _gNew.load('auth2', () => {
            _gNew.auth2.init({ client_id: this.googleClientID });
            setTimeout(() => {
              // this.initOAuth2(); don't sign into this page, directly ask for offline data access
              this.authChecked = true;
              this.loggedIn = true;
            }, 100);
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('auth2.load', err);
          this.$store.dispatch('showError', err);
        }
      }, 100);
    },
    initOAuth2(): void {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _gNew = (window as any).gapi;
        _gNew.signin2.render('google-button', {
          scope: this.scopes,
          width: 240,
          height: 36,
          longtitle: true,
          theme: 'dark',
          onsuccess: this.alreadySignedIn,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onfailure: (err: any): void => {
            if (err.error === 'popup_closed_by_user') {
              this.clear();
              return;
            }
            // eslint-disable-next-line no-console
            console.error('auth2.onfailure', err);
            this.error = err.details || err.message || err.error;
            this.$store.dispatch('showError', err);
          },
        });
        setTimeout(() => {
          this.authChecked = true; // show the 'google sign in' button
        }, 1000);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('auth2.init', err);
        this.$store.dispatch('showError', err);
      }
    },
    checkGoogleOAuthNew(): void {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _gNew = (window as any).gapi;
        const auth2 = _gNew.auth2.getAuthInstance();
        auth2
          .grantOfflineAccess({ scope: this.scopes })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((resp: any) => {
            // note: no user ID now that we're not asking for logging in into google, just asking for offline access token
            this.saving = true;
            this.showIntro = false;
            this.$store
              .dispatch('checkGoogleOAuthNew', { token: resp.code, agencyId: this.agencyId })
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .then((data: any) => {
                if (data && data.error) {
                  // eslint-disable-next-line no-console
                  console.log('checkGoogleOAuthNew data error 1', data);
                  this.$store.dispatch('showError', 'sorry, an error occurred');
                  return;
                }
                if (data.Message) this.message = data.Message;

                this.$emit('success', data.Message);
              })
              .catch((error: Error) => {
                if (error.message.includes('is already exist in current agency')) {
                  this.message = error.message;
                  this.$emit('existingAccount', error.message);
                  return;
                }
                // eslint-disable-next-line no-console
                console.log('checkGoogleOAuthNew error 2', error);
                this.$emit('showError', error.message);
                this.$store.dispatch('showError', 'sorry, an error occurred');
              });
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('checkGoogleOAuthNew error', err);
            this.$emit('showError', err.error);
            if (err.error === 'popup_closed_by_user') {
              this.clear();
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('auth2.load', err);
        this.$store.dispatch('showError', err);
      }
    },
    LoadGoogleApi(): void {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://apis.google.com/js/platform.js?onload=GoogleApiLoaded';
      document.head.appendChild(script);
    },
    tryAgain(): void {
      this.clear();
      this.checkGoogleOAuthNew();
    },
    clear(): void {
      this.error = '';
      this.gaData = null;
      this.showIntro = true;
    },
  },
});
